import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

interface BaseUserAttributes {
    oid: string;
    email: string;
    name: string;
}


const kubi_url = "https://jhfuyl41wj.execute-api.us-east-1.amazonaws.com/dev";



export function useKubiUser()
{
    const {instance, accounts, inProgress} = useMsal();

    const [loading, setLoading] = useState(true);

    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

    const [user, setUser] = useState<BaseUserAttributes | undefined>(undefined);

    useEffect(() => {
        if(accessToken)
        {
            const info = instance.getActiveAccount();
            setUser({
                oid: info?.idTokenClaims?.oid || "",
                email: info?.idTokenClaims?.upn || "",
                name: info?.idTokenClaims?.name || "",
            });
        }
    }, [accessToken]);

    useEffect(() => {
        //if there's no active account force the user to choose which account to use
        if(instance.getActiveAccount() === null){
            instance.loginRedirect({
                scopes: ["openid", "offline_access", "api://f7345a44-6ba1-45da-b32d-2fa1edfce2fe/studio"],
            });
            return;
        }


        var request = {
            scopes: ["openid", "offline_access", "api://f7345a44-6ba1-45da-b32d-2fa1edfce2fe/studio"],
        };
        
        instance.acquireTokenSilent(request).then(tokenResponse => {
            // Do something with the tokenResponse
            console.log(tokenResponse.accessToken);
            setAccessToken(tokenResponse.accessToken);
            setLoading(false);
            //console.log(tokenResponse.idToken);
        }).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                console.error(error);
                // fallback to interaction when silent call fails
                instance.acquireTokenRedirect(request);
            }
            console.error(error);
            // handle other errors
        });
    }, [])


    const fetchFromKubiAPI = async (endpoint : string, init: RequestInit) =>
    {
        const base_headers = init.headers || new Headers();

        const fin_auth_headers = {
            ...base_headers,
            "Authorization": "Bearer " + accessToken,
        };

        init.headers = fin_auth_headers;

        const response = await fetch(kubi_url +"/"+ endpoint, init);

        const data = await response.text();

        return data;
    }

    return {
        user,
        fetchFromKubiAPI,
        loading,
    }
}