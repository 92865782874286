import { GenIcon } from "react-icons";

const outerPath = `M4845 7979 c-154 -17 -383 -68 -560 -127 -378 -124 -735 -348 -1015
-639 -144 -149 -205 -224 -314 -387 -187 -281 -329 -642 -381 -971 -25 -157
-31 -513 -11 -660 43 -308 113 -541 237 -785 278 -550 744 -997 1275 -1223
359 -153 667 -216 1060 -217 249 0 292 9 347 69 55 61 60 120 15 184 -40 57
-60 61 -328 63 -319 2 -465 21 -705 91 -754 219 -1363 853 -1549 1614 -47 196
-59 313 -53 544 7 274 33 411 124 658 49 135 98 172 178 135 27 -12 65 -73 65
-103 0 -9 -18 -70 -40 -136 -74 -221 -100 -383 -100 -616 0 -322 64 -597 204
-874 209 -413 518 -716 933 -916 314 -151 602 -211 963 -200 320 10 572 70
845 202 100 48 286 163 346 213 179 149 282 251 378 374 201 256 343 607 387
958 103 826 -337 1635 -1102 2023 -190 97 -338 147 -559 189 -103 19 -153 22
-355 23 -260 0 -322 -7 -528 -61 -191 -49 -334 -107 -486 -197 -111 -66 -206
-53 -222 30 -11 57 15 93 107 147 221 132 497 228 784 272 162 25 509 26 675
0 222 -33 436 -100 649 -202 157 -75 225 -116 381 -232 456 -339 774 -849 871
-1399 19 -104 23 -167 23 -333 1 -283 -26 -446 -115 -700 -47 -132 -54 -166
-45 -215 9 -46 65 -102 111 -111 50 -9 121 13 149 47 71 84 162 410 202 719
20 158 15 441 -10 602 -86 543 -343 1030 -752 1428 -138 134 -233 212 -360
297 -319 214 -654 343 -1060 409 -120 19 -527 27 -659 13z m617 -858 c571
-125 1049 -526 1263 -1061 92 -229 121 -397 112 -653 -6 -195 -24 -302 -78
-462 -89 -261 -220 -467 -417 -657 -243 -234 -535 -391 -852 -458 -396 -83
-802 -29 -1159 155 -352 181 -662 512 -804 855 -210 509 -151 1072 162 1541
268 401 697 676 1178 754 134 22 468 14 595 -14z`;

const magnifyingGlassTail = `M5762 5749 c-113 -15 -207 -85 -261 -199 -22 -47 -26 -68 -26 -150 0
-84 3 -101 28 -148 37 -70 97 -128 166 -160 46 -22 71 -26 141 -27 73 0 94 4
148 29 214 99 272 365 118 541 -78 90 -188 130 -314 114z`;

const leftEye = `M4310 5733 c-105 -36 -194 -135 -220 -244 -55 -228 134 -447 366
-426 125 12 234 88 291 205 39 79 40 191 2 272 -37 78 -99 143 -171 179 -54
27 -71 31 -142 31 -47 -1 -100 -8 -126 -17z`;

const rightEye = `M2463 4370 c-75 -34 -106 -111 -79 -202 29 -102 213 -397 365 -588
93 -117 327 -352 441 -443 323 -257 701 -458 1057 -560 234 -68 428 -101 699
-119 379 -24 821 37 1024 143 249 129 345 385 232 617 -34 69 -121 165 -175
191 -79 38 -169 23 -220 -38 -58 -70 -42 -153 47 -239 29 -28 59 -65 66 -82
30 -73 -39 -163 -152 -198 -325 -101 -817 -109 -1248 -22 -325 66 -698 230
-984 432 -357 255 -625 558 -802 911 -79 159 -94 180 -138 201 -44 21 -81 20
-133 -4z`;

const mouth = `M6692 3902 c-74 -27 -122 -69 -159 -141 -28 -53 -33 -74 -33 -129 0
-83 12 -127 49 -182 16 -24 207 -224 425 -446 448 -456 453 -459 581 -459 81
1 146 29 207 90 60 61 82 117 83 210 0 132 -4 138 -473 612 -444 450 -446 452
-566 460 -43 2 -77 -2 -114 -15z`;

export function KubiIcon(props: any) {
    return GenIcon({
        "tag": "svg",
        "attr": { 
            "viewBox": "0 0 16 16", 
            "fill": "currentColor",
        }, 
        "child": [
            { "tag": "g", "attr": { 
                    "fill": "currentColor", 
                    "transform":"translate(-6.000000,22.000000) scale(0.002700000,-0.002700000)",
                }, "child": [
                { "tag": "path", "attr": { "d": outerPath, "fill" : "currentColor" }, "child": [] },
                { "tag": "path", "attr": { "d": magnifyingGlassTail, "fill":"currentColor" }, "child": [] },
                { "tag": "path", "attr": { "d": leftEye, "fill":"currentColor" }, "child": [] },
                { "tag": "path", "attr": { "d": rightEye, "fill":"currentColor" }, "child": [] },
                { "tag": "path", "attr": { "d": mouth, "fill":"currentColor" }, "child": [] }
            ]}
        ]
    })(props);
};