import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import sign_in_button from "./ms-symbollockup_signin_light.svg";
import { KubiIcon } from "../../components/Kubi Assets/KubiReactIcon";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).then(response => {
                instance.setActiveAccount(response.account);
            }).catch(e => {
                console.log(e);
                
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).then(response => {
                instance.setActiveAccount(response.account);
            }).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <div className="flex flex-col border border-gray-800 p-3 rounded-md">
            <div className="flex flex-row items-center justify-center p-4">
                <KubiIcon className="w-14 h-14 " />
                <h1 className="text-xl font-bold">Kubi Studio</h1>
            </div>
            <button onClick={() => handleLogin("popup")}>
                <img src={sign_in_button} alt="sign-in button" />
            </button>
        </div>
    )
}