import { useEffect, useState } from "react";
import { AssistantSettings, GETAssistantResponse, PATCHAssistantResponse } from "./assistantsAPI";
import { useKubiUser } from "./useKubiUser";




export function useAssistantSettings(assistantId: string | undefined)
{
    const {
        user,
        fetchFromKubiAPI,
        loading,
    } = useKubiUser();

    const [assistantSettings, setAssistantSettings] = useState<AssistantSettings>();


    const loadAssistantSettings = async () => {
        const response = await fetchFromKubiAPI(`assistants/${assistantId}`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const settings = JSON.parse(response) as GETAssistantResponse;
        setAssistantSettings(settings.assistants[0]);
    }

    const updateAssistantSettings = async (settings: AssistantSettings) => {
        const response = await fetchFromKubiAPI(`assistants`,
        {
            method: "PATCH",
            body: JSON.stringify(settings),
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const response_p = JSON.parse(response) as PATCHAssistantResponse;

        var currentSettings = assistantSettings ?? {} as AssistantSettings;

        currentSettings.seed = response_p.seed;
        currentSettings.prompt = response_p.prompt;
        currentSettings.temperature = response_p.temperature;
        currentSettings.name = response_p.name;
        currentSettings.description = response_p.description;
        

        setAssistantSettings(currentSettings);
    }

    useEffect(() => { 
        if(!loading) loadAssistantSettings();
    }, [assistantId, loading]);

    return {
        assistantSettings,
        updateAssistantSettings,
    }

}