import { useEffect, useState } from "react";
import { KubiQuestion, useKubiQuestions } from "../KubiClient/useKubiQuestions";
import { VscAdd, VscCheck, VscClearAll, VscErrorSmall, VscLiveShare, VscLoading, VscRefresh, VscTrash } from "react-icons/vsc";
import { useDebounce } from "@uidotdev/usehooks";
import DeleteQuestionModal from "../components/DeleteQuestionModal";
import { useParams } from "react-router-dom";
import { KubiIcon } from "../components/Kubi Assets/KubiReactIcon";

export function KubiQuestionsView()
{
    let { assistantId } = useParams();

    //TODO: add an error when the assistant is set but not found
    const { questions, addQuestion, loading, updateAnswer, refresh  } = useKubiQuestions(assistantId);
    const [selectedQuestion, setSelectedQuestion] = useState<string | undefined>(undefined);

    const tombstones = Array(6).fill(0).map((_, index) => <TombStoneQuestionView key={index}/>);

    const selectedKubiQuestion = questions.find((question) => question.questionID === selectedQuestion);
    const selectedQuestionID = selectedKubiQuestion?.questionID ?? "none";

    console.log(selectedQuestionID);

    const question_list = questions.map((question, index) => <QuestionView 
        question={question.question} 
        answer={question.answer} 
        rank={question.rank} 
        hit_count={question.hit_count}
        setSelectedQuestion={setSelectedQuestion}
        selectedQuestionID={selectedQuestionID}
        questionID={question.questionID}
        key={question.questionID}
    />);


    return(
        <div className=" w-full flex-col flex h-full static">
            <div className="flex flex-col h-2/3 flex-grow-0 static">
                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-1 w-full static h-full overflow-y-scroll p-4">
                    {loading ? tombstones : question_list}
                    
                </div>
                <div className="flex flex-row items-center justify-between bg-gray-100 border-gray-300">
                    <button className="bg-gray-400 hover:bg-gray-600 text-white p-1 px-2 flex items-center justify-center gap-2 text-sm h-full" onClick={() => {
                            //open a new window to https://kubi.chat?id={assistantId}
                            window.open(`https://kubi.chat?id=${assistantId}`, "_blank")
                        }}>
                        <KubiIcon /> View Live Bot 
                    </button>
                    <AddNewQuestionComponent addQuestion={addQuestion}/>
                </div>
            </div>
            <div className="h-1/3 flex-shrink-0 border-t">
                {selectedKubiQuestion ? <SelectedQuestionView kubiQuestion={selectedKubiQuestion} updateAnswer={updateAnswer}/> : <TombStoneSelectedQuestionView/>}
            </div>
            
            
        </div>
    );
}

function QuestionView(props: {question: string, answer: string | undefined, hit_count: number, rank: number, questionID: string, selectedQuestionID : string, setSelectedQuestion: (questionID: string) => void })
{
    const selected_class = " bg-gray-300 relative text-gray-600 px-2 py-2 cursor-pointer flex items-center justify-between"
    const unselected_class = "bg-gray-100 relative text-gray-600 px-2 py-2 cursor-pointer flex items-center justify-between"
    const selected = props.questionID === props.selectedQuestionID;

    return(
        <div className={selected ? selected_class : unselected_class} onClick={()=>props.setSelectedQuestion(props.questionID)}>
            <div className="text-sm font-medium relative p-0.5">{ props.question }</div>
            <div className="text-xs font-bold flex items-center justify-center flex-col">
                { props.hit_count }
                { props.answer ? "" : <NoAnswerTag/>}
            </div>
        </div>
    );

}

function NoAnswerTag()
{
    return(
        <span className="text-xs text-sky-600 flex items-center justify-center flex-shrink-0"> 
            <VscClearAll/>
        </span>
    )

}

function TombStoneQuestionView()
{
    return(
        <div className="flex-grow-0 bg-gray-100 animate-pulse flex items-center px-4 py-2 m-1 cursor-pointer select-none">
            <div className="flex flex-row items-start justify-between w-full">
                <div className="text-sm font-medium text-gray-600"></div>
                <span className="text-xs bg-gray-200 text-white flex items-center justify-center rounded-full w-5 h-5 flex-shrink-0"> </span>
            </div>
        </div>
    )

}

function TombStoneSelectedQuestionView()
{
    return(
        <div className="h-full bg-gray-100 flex flex-col">
            <div className="flex flex-row items-center justify-between select-none p-2 flex-grow-0 ">
                <div className="text-lg font-black text-gray-600 flex-grow ">
                    Select a question to view the answer
                </div>
                <button className="bg-red-100 text-red-400 rounded w-8 h-8 flex items-center justify-center flex-shrink-0 mr-2"><VscTrash/></button>
                <span className="text-xs bg-gray-200 text-white flex items-center justify-center rounded-full w-5 h-5 flex-shrink-0">1 </span>
            </div>
            <div className="flex-grow overflow-y-auto">
                <div className="h-full flex flex-col static">
                    <textarea 
                        disabled
                        className="text-sm bg-transparent flex-grow static text-gray-500 block resize-none border-none focus:ring focus:ring-sky-200 focus:ring-opacity-50"
                        value={"Select a question to view the answer"}
                        onChange={(e) => {}} 
                    />
                </div>
            </div>
        </div>
    )

}

export enum SaveState {
    SAVED,
    SAVING
}

export const saveStateToIcon = (saveState: SaveState) => {
    switch(saveState){
        case SaveState.SAVED:
            return <VscCheck className="bg-lime-300 text-white rounded-full p-0.5"/>
        case SaveState.SAVING:
            return <VscLoading className="text-gray-300 animate-spin"/>
    }
}

function SelectedQuestionView(props: { kubiQuestion: KubiQuestion, updateAnswer: (questionId : string, answer: string) => Promise<void> })
{
    const [saveState, setSaveState] = useState<SaveState>(SaveState.SAVED);
    const [answer, setAnswer] = useState<string | undefined>(props.kubiQuestion.answer);

    console.log(props.kubiQuestion.answer);

    const [deleteQuestionModalOpen, setDeleteQuestionModalOpen] = useState<boolean>(false);

    const debouncedAnswer = useDebounce(answer, 1000);

    const question = props.kubiQuestion?.question ?? "Select a question to view the answer";

    useEffect(() => {
        setAnswer(props.kubiQuestion.answer);
    },[props.kubiQuestion])

    useEffect(() => {
        const debouncedUpdateAnswer = async (new_answer: string | undefined) => {
            
            if(new_answer && new_answer !== props.kubiQuestion.answer){
                console.log("saving");
                const result = await props.updateAnswer(props.kubiQuestion.questionID, new_answer);
                setSaveState(SaveState.SAVED);
            }
            else{
                //No changes to save
                setSaveState(SaveState.SAVED);
            }
        }
        debouncedUpdateAnswer(debouncedAnswer);
    }, [debouncedAnswer]);

    useEffect(() => {
        if(answer === props.kubiQuestion.answer) setSaveState(SaveState.SAVED);
    }, [answer])

    return(
        <div className="h-full flex flex-col">
            <div className="flex flex-row items-center justify-between select-none p-2 flex-grow-0 space-x-2 bg-gray-100">
                <div className="text-lg font-black text-gray-700 flex-grow">{question}</div>
                <div className="flex items-center justify-center text-gray-400 p-2">
                    {saveStateToIcon(saveState)}
                </div>
                <button className="bg-red-100 rounded text-red-400 hover:bg-gray-300 w-8 h-8 flex items-center justify-center flex-shrink-0" onClick={
                    () => setDeleteQuestionModalOpen(true)
                }><VscTrash/></button>
                <span className="text-xs bg-sky-300 text-white flex items-center justify-center rounded-full w-5 h-5 flex-shrink-0"> { 1 }</span>
            </div>
            <div className="flex-grow overflow-y-auto">
                <div className="h-full flex flex-col static">
                    <textarea 
                        className="text-sm flex-grow static bg-gray-100 text-gray-500 block border resize-none border-none focus:ring focus:ring-gray-50 focus:ring-opacity-50 pr-6"
                        value={answer ?? ""}
                        placeholder={"write an answer here..."}
                        onChange={(e) => {
                            setAnswer(e.target.value)
                            setSaveState(SaveState.SAVING);
                        }}
                    />
                    
                </div>
            </div>
            <DeleteQuestionModal 
                open={deleteQuestionModalOpen}
                setOpen={setDeleteQuestionModalOpen}
                question={question}
                onConfirm={() => console.log("delete")}
            />
        </div>
    )
}


function AddNewQuestionComponent(props : {addQuestion: (question: string) => void}){

    const [question, setQuestion] = useState("");

    return (
        <div className="flex flex-col filter h-10 w-full xl:max-w-4xl sm:max-w-xl flex-grow bg-gray-100">
            <div className="flex flex-row items-center h-full ">
                <input 
                className="text-sm block bg-gray-200 w-full h-10 flex-grow border-none resize-none ring-0 ring-white focus:ring-0 focus:ring-white" 
                value={question}
                type="text"
                placeholder={"Add a new question"}
                onChange={(e) => setQuestion(e.target.value)}
                onKeyDown={(e) => {
                    if(e.key === "Enter") {
                        props.addQuestion(question)
                        setQuestion("");
                    }
                }}
                />
                <button 
                    className="bg-gray-400 hover:bg-gray-700 cursor-pointer text-white aspect-square h-full flex items-center justify-center font-medium" 
                    onClick={() => {
                        props.addQuestion(question)
                        setQuestion("");
                    }}
                >
                    <VscAdd/>
                </button>
            </div>
        </div>
            
    );
}