import { useState } from "react";
import { VscCallOutgoing, VscCommentDiscussion, VscSettingsGear } from "react-icons/vsc";
import { IconType } from "react-icons/lib";
import { KubiIcon } from "../components/Kubi Assets/KubiReactIcon";

export enum ToolChoices {
    QuestionAndAnswer = "QuestionAndAnswer",
    AssistantSettings = "AssistantSettings",
    SwitchBoard = "SwitchBoard"
}

const ToolChoiceIcons : { [s in ToolChoices]: IconType } = {
    "QuestionAndAnswer": VscCommentDiscussion,
    "AssistantSettings": VscSettingsGear,
    "SwitchBoard": VscCallOutgoing
}

export default function ToolBar(props: { toolChoice : ToolChoices, setToolChoice : (tool: ToolChoices) => void }) {

    const tool_buttons = Object.keys(ToolChoices).map((key) => {
        const tool = ToolChoices[key as keyof (typeof ToolChoices)];
        const Icon = ToolChoiceIcons[tool];
        return(
            <ToolBarIcon icon={<Icon/>} key={key} title={key} onClick={() => props.setToolChoice(tool)} highlight={tool === props.toolChoice}/>
        )
    });

    return (
        <div className="sm:h-full flex items-center sm:relative absolute bottom-0 left-0 w-screen sm:w-min h-12 z-50">
            <div className=" h-full flex w-full sm:w-min">
                <div className="h-full sm:w-14 w-full rounded-l-md bg-gray-200 py-2 flex sm:flex-col items-center justify-center">
                    {tool_buttons}
                </div>
            </div>
        </div>
    );
} 

function ToolBarIcon(props: {icon: React.ReactNode, onClick?: any, highlight?: boolean, title?: string }){
    return(
        <div className={`w-14 h-12 text-2xl 
            ${ props.highlight ? " border-sky-600 text-sky-600" : "text-gray-500 border-transparent" }
            hover:text-sky-600 sm:border-r-2 cursor-pointer flex items-center justify-center `} 
            onClick={props.onClick}
            title={props.title}
            >
            {props.icon}
        </div>
    )
}