import { Link } from "react-router-dom";
import { useKubiAssistants } from "../KubiClient/useKubiAssistants";
import { AssistantSettings } from "../KubiClient/assistantsAPI";
import { VscAdd } from "react-icons/vsc";
import { KubiIcon } from "../components/Kubi Assets/KubiReactIcon";


export default function AssistantSelection() {
    const {
        assistants,
        addAssistant,
        loading,
    } = useKubiAssistants();

    const assistantCards = assistants.map((assistant) => {
        return <AssistantCard assistant={assistant} key={assistant.id}/>
    });

    const assistantTombstones = Array(6).fill(0).map((_, index) => {
        return <TombstoneAssistantCard key={index}/>
    });

    const assistantCardsOrTombstones = loading ? assistantTombstones : assistantCards;

    return (
        <div className="items-center flex-col justify-center sm:p-2 flex p-4">
            <div className="w-full border-b p-4 absolute top-0">
                <div className="flex flex-row items-center gap-2">
                    <KubiIcon className="h-8" />
                    <h1 className="font-black text-lg">
                        Kubi Studio
                    </h1>
                </div>
            </div>
            <div className="rounded-md border-gray-900 border p-4  flex flex-col gap-2">
                <div className="flex flex-row justify-between">
                    <div className="font-semibold text-lg text-gray-700 flex items-end justify-end h-8"><p>Assistants</p></div>
                    <button className=" bg-neutral-700 hover:bg-gray-700 text-white font-bold p-2 rounded h-8 w-8"
                        onClick={() => addAssistant()}>
                        <VscAdd size={16} />
                    </button>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 ">
                    {assistantCardsOrTombstones}
                </div>
            </div>
        </div>
    );
}

function TombstoneAssistantCard()
{
    return(
        <div className="bg-gray-100 rounded-md p-3 h-20 w-64">
            <p className="animate-pulse font-semibold text-gray-400">..</p>
            <p className="animate-pulse text-gray-200">..</p>
        </div>
    );

}

function AssistantCard(props: { assistant: AssistantSettings})
{
    //using hashrouter link to /assistant and pass the assistant id as a parameter

    return(
        <Link to={`/assistant/${props.assistant.id}`}>
            <div className="bg-neutral-100 hover:bg-gray-300 rounded-md p-3 h-20">
                <p className=" font-semibold text-gray-700">{props.assistant.name}</p>
                <p className=" text-gray-500">{props.assistant.description}</p>
            </div>

        </Link>
    );
}