import { useEffect, useState } from "react";

const dev_kubi_url = "https://jhfuyl41wj.execute-api.us-east-1.amazonaws.com/dev/questions";

export interface KubiQuestion {
    knowledgeID : string, //Partition Key
    questionID : string,
    question : string,
    answer? : string,
    hit_count : number, //number of times this question has been referenced

    //This isn't stored in the database, but is computed by the server before returning from the API
    rank : number //rank of this question in the knowledge base
}

export interface KubiQuestionPATCHRequest {
    questionId: number;
    knowledgeId: string;
    answer: string;
}

export function useKubiQuestions(knowledgeBaseId : string | undefined)
{
    const [questions, setQuestions] = useState<KubiQuestion[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(knowledgeBaseId) getQuestionsFromDB();
    }, [knowledgeBaseId]);

    if(!knowledgeBaseId) return {
        questions : [],
        loading: false,
        refresh() {},
        async addQuestion(question : string) {},
        async updateAnswer(questionId : string, answer : string) {},
    }

    

    const getQuestionsFromDB = async () =>
    {
        setLoading(true);
        const response = await fetch(dev_kubi_url + `/${knowledgeBaseId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data : KubiQuestion[] = await response.json();

        setQuestions(data);
        setLoading(false);
    }

    const addQuestionToDB = async (question : string) => {

        const response = await fetch(dev_kubi_url, {
            method: "PUT",
            mode: "cors",
            body: JSON.stringify({
                knowledgeId : knowledgeBaseId,
                question : question,
                answer : "",
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data : KubiQuestion[] = await response.json();
    }

    //PATCH request to update answer
    const updateAnswer = async (questionId : string, answer : string) => {
        const response = await fetch(dev_kubi_url, {
            method: "PATCH",
            mode: "cors",
            body: JSON.stringify({
                questionId : questionId,
                knowledgeId : knowledgeBaseId,
                answer : answer,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data : KubiQuestion[] = await response.json();

        return data;
    }

    const refresh = async () => {
        await getQuestionsFromDB();
    }


    return {
        questions : questions,
        loading: loading,
        async refresh() {
            await getQuestionsFromDB();
        },
        async addQuestion(question : string) {
            if(question.length === 0) return;
            await addQuestionToDB(question);
            await refresh();
        },
        async updateAnswer(questionId : string, answer : string) {
            await updateAnswer(questionId, answer);
            await refresh();
        }
    };
}