import { useParams } from "react-router-dom";
import { useAssistantSettings } from "../KubiClient/useAssistantSettings";
import { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { AssistantSettings } from "../KubiClient/assistantsAPI";
import { SaveState, saveStateToIcon } from "./KubiQuestionsView";



export default function AssistantSettingsView() {

    let { assistantId } = useParams();

    const { assistantSettings, updateAssistantSettings } = useAssistantSettings(assistantId);
    

    return (
        <div className="flex flex-col p-4">
            <h1>Assistant Settings</h1>
            <p> Assistant ID: {assistantSettings?.id}</p>
            { assistantSettings && <NameEditView assistantSettings={assistantSettings} updateAssistantSettings={updateAssistantSettings}/>}
            { assistantSettings && <DescriptionEditView assistantSettings={assistantSettings} updateAssistantSettings={updateAssistantSettings}/>}
            { assistantSettings && <SeedEditView assistantSettings={assistantSettings} updateAssistantSettings={updateAssistantSettings}/>}
            { assistantSettings && <TemperatureEditView assistantSettings={assistantSettings} updateAssistantSettings={updateAssistantSettings}/>}
            { assistantSettings && <PromptEditView assistantSettings={assistantSettings} updateAssistantSettings={updateAssistantSettings}/>}
        </div>
    );    

}

function TemperatureEditView(props: { assistantSettings: AssistantSettings, updateAssistantSettings: (assistantSettings: AssistantSettings) => Promise<void> }){
    const [temperature, setTemperature] = useState<number>(props.assistantSettings.temperature);
    const [saveState, setSaveState] = useState<SaveState>(SaveState.SAVED);

    const debouncedTemperature = useDebounce(temperature, 1000);

    useEffect(() => {
        const debouncedUpdateTemperature = async (new_temperature: number) => {
            if(new_temperature !== props.assistantSettings.temperature){
                console.log("saving");
                const result = await props.updateAssistantSettings({...props.assistantSettings, temperature: new_temperature});
            }

            setSaveState(SaveState.SAVED);
        }
        debouncedUpdateTemperature(debouncedTemperature);
    }, [debouncedTemperature]);

    useEffect(() => {
        if(temperature === props.assistantSettings.temperature) setSaveState(SaveState.SAVED);
    }, [temperature])

    return(
        <div className="flex flex-row items-center justify-between p-1 bg-white">
            <div className="text-gray-700 font-semibold">Temperature</div>
            <p>{temperature}</p>
            <input
                type="range"
                min={0}
                max={2}
                step={0.1} 
                className="text-sm static text-gray-500 block border resize-none border-none focus:ring focus:ring-gray-50 focus:ring-opacity-50"
                value={temperature}
                placeholder={"write a temperature here..."}
                onChange={(e) => {
                    setTemperature(e.target.valueAsNumber)
                    setSaveState(SaveState.SAVING);
                }}
            />
            <div className="flex items-center justify-center text-gray-400">
                {saveStateToIcon(saveState)}
            </div>
        </div>
    )
}

function SeedEditView(props: { assistantSettings: AssistantSettings, updateAssistantSettings: (assistantSettings: AssistantSettings) => Promise<void> }){
    const [seed, setSeed] = useState<number>(props.assistantSettings.seed);
    const [saveState, setSaveState] = useState<SaveState>(SaveState.SAVED);

    const debouncedSeed = useDebounce(seed, 1000);

    useEffect(() => {
        const debouncedUpdateSeed = async (new_seed: number) => {
            if(new_seed !== props.assistantSettings.seed){
                console.log("saving");
                const result = await props.updateAssistantSettings({...props.assistantSettings, seed: new_seed});
                setSaveState(SaveState.SAVED);
            }
            else{
                //No changes to save
                setSaveState(SaveState.SAVED);
            }
        }
        debouncedUpdateSeed(debouncedSeed);
    }, [debouncedSeed]);

    useEffect(() => {
        if(seed === props.assistantSettings.seed) setSaveState(SaveState.SAVED);
    }, [seed])

    return(
        <div className="flex flex-row items-center justify-start p-1 bg-white">
            <div className="text-gray-700 font-semibold">Seed</div>
            <input
                type="number" 
                className="text-sm flex-grow static text-gray-500 block border resize-none border-none focus:ring focus:ring-gray-50 focus:ring-opacity-50"
                value={seed}
                placeholder={"write a seed here..."}
                onChange={(e) => {
                    setSeed(parseInt(e.target.value))
                    setSaveState(SaveState.SAVING);
                }}
            />
            <div className="flex items-center justify-center text-gray-400">
                {saveStateToIcon(saveState)}
            </div>
        </div>
    )
}

function DescriptionEditView(props: { assistantSettings: AssistantSettings, updateAssistantSettings: (assistantSettings: AssistantSettings) => Promise<void> }){
    const [description, setDescription] = useState<string>(props.assistantSettings.description);
    const [saveState, setSaveState] = useState<SaveState>(SaveState.SAVED);

    const debouncedDescription = useDebounce(description, 1000);

    useEffect(() => {
        const debouncedUpdateDescription = async (new_description: string) => {
            if(new_description !== props.assistantSettings.description){
                console.log("saving");
                const result = await props.updateAssistantSettings({...props.assistantSettings, description: new_description});
                setSaveState(SaveState.SAVED);
            }
            else{
                //No changes to save
                setSaveState(SaveState.SAVED);
            }
        }
        debouncedUpdateDescription(debouncedDescription);
    }, [debouncedDescription]);

    useEffect(() => {
        if(description === props.assistantSettings.description) setSaveState(SaveState.SAVED);
    }, [description])

    return(
        <div className="flex flex-row items-center justify-start p-1 bg-white">
            <div className="text-gray-700 font-semibold">Description</div>
            <input
                type="text" 
                className="text-sm flex-grow static text-gray-500 block border resize-none border-none focus:ring focus:ring-gray-50 focus:ring-opacity-50"
                value={description}
                placeholder={"write a description here..."}
                onChange={(e) => {
                    setDescription(e.target.value)
                    setSaveState(SaveState.SAVING);
                }}
            />
            <div className="flex items-center justify-center text-gray-400">
                {saveStateToIcon(saveState)}
            </div>
        </div>
    )
}

function NameEditView(props: { assistantSettings: AssistantSettings, updateAssistantSettings: (assistantSettings: AssistantSettings) => Promise<void> }){
    const [name, setName] = useState<string>(props.assistantSettings.name);
    const [saveState, setSaveState] = useState<SaveState>(SaveState.SAVED);

    const debouncedName = useDebounce(name, 1000);

    useEffect(() => {
        const debouncedUpdateName = async (new_name: string) => {
            if(new_name !== props.assistantSettings.name){
                console.log("saving");
                const result = await props.updateAssistantSettings({...props.assistantSettings, name: new_name});
                setSaveState(SaveState.SAVED);
            }
            else{
                //No changes to save
                setSaveState(SaveState.SAVED);
            }
        }
        debouncedUpdateName(debouncedName);
    }, [debouncedName]);

    useEffect(() => {
        if(name === props.assistantSettings.name) setSaveState(SaveState.SAVED);
    }, [name])

    return(
        <div className="flex flex-row items-center justify-start p-1 bg-white">
            <div className="text-gray-700 font-semibold">Name</div>
            <input
                type="text" 
                className="text-sm flex-grow static text-gray-500 block border resize-none border-none focus:ring focus:ring-gray-50 focus:ring-opacity-50"
                value={name}
                placeholder={"write a name here..."}
                onChange={(e) => {
                    setName(e.target.value)
                    setSaveState(SaveState.SAVING);
                }}
            />
            <div className="flex items-center justify-center text-gray-400">
                {saveStateToIcon(saveState)}
            </div>
        </div>
    )
}

function PromptEditView(props: { assistantSettings: AssistantSettings, updateAssistantSettings: (assistantSettings: AssistantSettings) => Promise<void> }){
    const [prompt, setPrompt] = useState<string>(props.assistantSettings.prompt);
    const [saveState, setSaveState] = useState<SaveState>(SaveState.SAVED);

    const debouncedPrompt = useDebounce(prompt, 1000);

    useEffect(() => {
        const debouncedUpdatePrompt = async (new_prompt: string) => {
            if(new_prompt !== props.assistantSettings.prompt){
                console.log("saving");
                const result = await props.updateAssistantSettings({...props.assistantSettings, prompt: new_prompt});
                setSaveState(SaveState.SAVED);
            }
            else{
                //No changes to save
                setSaveState(SaveState.SAVED);
            }
        }
        debouncedUpdatePrompt(debouncedPrompt);
    }, [debouncedPrompt]);

    useEffect(() => {
        if(prompt === props.assistantSettings.prompt) setSaveState(SaveState.SAVED);
    }, [prompt])

    return(
        <div className=" bg-gray-100 pt-0.5 flex flex-col flex-grow">
            <div className="flex flex-row items-center justify-between select-none p-2 flex-grow-0 space-x-2 bg-white">
                <div className="text-lg font-black text-gray-700 flex-grow">Prompt</div>
                <div className="flex items-center justify-center text-gray-400 p-2">
                    {saveStateToIcon(saveState)}
                </div>
            </div>
            <div className="flex-grow">
                <div className="h-full flex flex-col static">
                    <textarea 
                        className="text-sm flex-grow static text-gray-500 block border border-none focus:ring focus:ring-gray-50 focus:ring-opacity-50 pr-6"
                        value={prompt}
                        placeholder={"write a prompt here..."}
                        onChange={(e) => {
                            setPrompt(e.target.value)
                            setSaveState(SaveState.SAVING);
                        }}
                    />
                </div>
            </div>
        </div>
    )
}