import AssistantSelection from "./ExternalBotEditor/AssistantSelection";
import ToolBox from "./ExternalBotEditor/ToolBox";
import { HashRouter, Route, Routes } from "react-router-dom";

export function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/assistant/:assistantId" Component={ToolBox} />
        <Route path="" Component={AssistantSelection} />
      </Routes>
    </HashRouter>
  );
}

export default App;