import ToolBar, { ToolChoices } from "./ToolBar";
import { useState } from "react";
import { KubiQuestionsView } from "./KubiQuestionsView";
import AssistantSettingsView from "./AssistantSettingsView";
import SwitchBoard from "./SwitchBoard";

const ToolChoiceComponents : { [s in ToolChoices]: React.ReactNode } = {
    "QuestionAndAnswer": <KubiQuestionsView/>,
    "AssistantSettings": <AssistantSettingsView/>,
    "SwitchBoard": <SwitchBoard/>
}

export default function ToolBox() {
    const [toolChoice, setToolChoice] = useState(ToolChoices.QuestionAndAnswer);

    const toolChoiceComponent = ToolChoiceComponents[toolChoice];

    return (
        <div className="w-screen h-screen flex">
            <ToolBar toolChoice={toolChoice} setToolChoice={setToolChoice}/>
            <ToolWrapper>
                {toolChoiceComponent}
            </ToolWrapper>
        </div>
    );
}

function ToolWrapper(props: {children: React.ReactNode})
{
    return(
        <div className="h-full w-full px-0.5 border-l border-gray-300 min-h-screen pb-12 sm:pb-0 pl-0 sm:pl-0">
            <div className="bg-gray-50 h-full w-full">
            {props.children}
            </div>
        </div>
    );
}

