import { useEffect, useState } from "react";
import { useKubiUser } from "./useKubiUser";
import { AssistantSettings, GETAssistantResponse, PUTAssistantResponse } from "./assistantsAPI";


export function useKubiAssistants()
{
    const {
        user,
        fetchFromKubiAPI,
    } = useKubiUser();

    const [assistants, setAssistants] = useState<AssistantSettings[]>([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(user) getAssistants();
    }, [user]);

    const getAssistants = async () =>
    {
        setLoading(true);
        const response = await fetchFromKubiAPI("assistants", 
        {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data  = JSON.parse(response) as GETAssistantResponse;
        setAssistants(data.assistants);
        setLoading(false);
    }

    const addAssistant = async () =>
    {
        setLoading(true);
        const response = await fetchFromKubiAPI("assistants", {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        });

        const data = JSON.parse(response) as PUTAssistantResponse;
        await getAssistants();
    }

    return {
        assistants: assistants,
        loading,
        getAssistants,
        addAssistant,
    }
}