


export interface SwitchBoardEntry{
    name: string;
    phone: string;
    description: string;
}

const switchBoardEntries: SwitchBoardEntry[] = [
    {
        name: "Front Desk",
        phone: "123-456-7890",
        description: " You should transfer to this number if you have a general question"
    },
    {
        name: "It Support",
        phone: "123-456-7891",
        description: " You should transfer to this number if you have a problem with your computer"
    }
]

function SwitchBoardEntryComponent(props: SwitchBoardEntry){
    return(
        <div className="bg-neutral-100  rounded-md p-3">
            <p className=" font-semibold text-gray-700">{props.name}</p>
            <p className=" text-gray-500">{props.phone}</p>
            <p className=" text-gray-500">{props.description}</p>
        </div>
    );
}


export default function SwitchBoard() {

    const entries = switchBoardEntries.map((entry) => {
        return <SwitchBoardEntryComponent key={entry.phone} {...entry}/>
    });

    return(
        <div className="bg-white  relative flex flex-col w-full h-full border-2 p-4 gap-2 rounded">
            <h1 className=" text-lg text-gray-800 w-max px-2 py-1 rounded">SwitchBoard</h1>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 rounded p-2 ">
                {entries}
            </div>
        </div>
    )

}